@-webkit-keyframes Animation {
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}
@keyframes Animation { 
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}
@keyframes pulse {
	0% {
		transform: scale(0.6);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.6);
	}
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url("Fonts/HelveticaNeue-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url("Fonts/HelveticaNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
iframe {display: none !important}
body {
  font-family: 'Helvetica Neue';
  font-size: 13px;
overflow: ;	
padding:0;
margin:0;
background:#eee;
-webkit-font-smoothing: antialiased;
font-smooth: auto;
color: #606367;

}
.infoPage {
  margin-right:50px;
}
.infoPage a {
  color: var(--button-bg);
  font-weight: 500;
  text-decoration: none;
}
body, html {
  /*min-height: 100vh !important;*/
}
.loginpopup.hide, .bgoverlay.hide{display:none;}
.loginpopup {
    position: fixed;
    padding: 40px;
    background: #fff;
    border-radius: 13px;
    outline: none;
    border: 0;
    font-size: 15px;
    color: #65676b;
    margin-top: -20px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index:9999999;
    box-shadow: 0px 0px 10px #00000041;
    line-height: 200%;
}
.loginpopup .intro {
    font-size: 13px;
}
.loginpopup input {
    outline: none;
    padding: 20px;
    font-size: 15px;
    background: var(--button-bg);
    font-family: 'Helvetica Neue', Helvetica Neue, Helvetica, Arial, sans-serif;
    outline:none;
    border:0;
    border-radius: 5px;
    margin-top: 20px;
    width: 100%;
    color: #fff;
}
.bgoverlay {
    width: 100%;
    height: 100%;
    overflow:hidden;
    position: fixed;
    left: 0;
    top: 0;
    background:#00000057;
    z-index:999;
}
.global_loader {
  width: 100%;
  text-align:center;
  position: fixed;
  font-size: 30px;
  font-weight: 500;
  z-index: 9999;
  color: #ccc;
	animation: pulse 1s infinite;
  top: 50vh;
  left:0;
}
.site .marketplace_grid .mintingText {
  width: 100%;
  text-align:center;
  position: fixed;
  font-size: 30px;
  font-weight: 500;
  z-index: 9999;
  color: #ccc;
	animation: pulse 5s infinite;
  top: 50vh;
  left:0;
}

.site * {
}
.stats_container {
  width:calc(100% + 50px);
  height:50vh;
  position: relative;
  margin: 0 auto;
  margin-left:-50px;
}
.recharts-polar-angle-axis-tick-value > * {
  fill: #65676b;
}
.timeline_postsection {
  width: calc(100% - 50px);
}
.timeline_postsection .title:first-of-type {margin-top:0px !important;}
.timeline_postsection .title {
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  margin-bottom: 40px;
  color: #606367;
  margin-top:50px;
}
.timeline_postsection .title img {
  vertical-align: middle;
  width: 13px;
  height: auto;
  margin-left: 10px;
  margin-right: 5px;
  border-left: 1px #ccc solid;
  padding-left: 15px;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -50px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 50px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
}
.timeline_menu {
  overflow: hidden;
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
  
}
.timeline_menu li {
  display: none;
}
.timeline_menu .active{
  display: block;
}
.timeline_menu.toggle {

}
.timeline_menu.toggle li {
  display:block;
}
.gen_section {
overflow:hidden;
}
.gen_section {
  margin: -25px;
  height: auto;
}
.gen_section > * {
  margin: 25px;
  
}
.timeline_postsection {}
.timeline_postsection section {
  display: block;
  overflow:;
}
@supports not (gap: 50px) {
}
.gen_section .box {
  width: calc(33% - 82px);
  display: inline-block;
  vertical-align:top;
  transition:width 1s ease;
  font-size: .9rem;
  }
.gen_section .box.focus {
  zoom: 1;
  flex: 1 0 50%;
    transition:width 1s ease;

}
*::selection{background:transparent;color:inherit;}
:root {
  --button-bg: #1877f2;
  --button-fill: #606367;
  min-height: 100%;
}
.site {
  text-align: left;
  font-size: .9375rem;
  padding: 0px;
  line-height:1.33;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  position:relative;
  height: 100%;
  background-size: 200% 200%;
background-attachment: fixed;
  -webkit-animation: Animation 2s ease infinite;
  -moz-animation: Animation 2s ease infinite;
  animation: Animation 2s ease infinite;
}
.site.bin, .site.three {
  background-image: linear-gradient(90deg, gray, #ffffff, gray);
  -webkit-animation: Animation 0s ease infinite;
  -moz-animation: Animation 0s ease infinite;
  animation: Animation 0s ease infinite;
}
.site.feed {
}
.toggleFilter {
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  margin-bottom: 40px;
  color: #606367;
}
.toggleFilter label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  zoom: 0.5;
  vertical-align: middle;

}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}
.switch.active .slider {
  background-color: var(--button-bg);
  box-shadow: 0 0 1px #2196F3;
}
.switch.active .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.feedBar {
  width: calc(100% - 70px);
  margin-bottom: 40px !important;
  position: fixed;
  top: 0;
  left:70px;
  color:#65676b;
  box-shadow:0px 0px 3px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  background: #eee;
  height: 70px;
  
}
.feedBar .info-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 22.5px;
  right: 22.5px;
}
.feedBar .info-icon svg {
  width: 100%;
  height: 100%;
  fill: var(--button-fill);
}
.feedBar .info-icon:hover svg {
  fill: var(--button-bg);

}
.feedBar .nav {
  position: absolute;
  top:0;
  z-index: 999999;
  height: 70px;
  width: 100%;
  top: -25px;
  display: none;
}
.feedBar .nav .up {
  position:absolute;
  top:20px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.feedBar .nav .down {
  position:absolute;
  bottom:20px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.feedBar .section {
  display:inline-block;
  height: 30px;
  overflow:;
  border-left: 1px #ccc solid;
  padding-left:30px;
  padding-right:30px;
  margin-top: 20px;
  
}
.feedBar .section:first-of-type {
  padding-left:50px;
  border:0;
}
.feedBar .inner {
  top: 50%;
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  position: relative;
  font-size: 16px;
  font-weight: 500;
  display:inline-block;
  vertical-align: middle;
}
.feedBar .inner .img {
  display: inline-block;
  width: 20px;
  height: 10px;
  position: relative;
  margin-right: 5px;
}
.feedBar .inner .img img {
  position: absolute;
    width: 15px;
    right:0;
    height: auto;
    transform: translate(0, -50%);
    top: 50%;
}
.feedBar .inner.second, .feedBar .inner.third {

}
.site .main {
  margin-left: 120px;
  margin-top: 120px;
  margin-bottom: 50px;
}
.site .feedInner {
  width: calc(100% - 50px);
  position:relative;
}
.site .grid {
  /*display: grid;
  grid-gap: 20px;
  grid-template-columns:calc(50% - 16px) calc(50% - 16px);*/
  width: 50%;
  position: relative;

  cursor:;
  overflow:;
  clear:both;
}

.site .marketplace_grid {
  width: 100%;
  /*column-count: 2;
  column-gap: 50px;
  column-fill: balance;
  -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;*/
               display: flex;
               justify-content: space-between;
               justify-content: space-between;
  flex-wrap: wrap;
  margin: -25px;
}
.site .marketplace_grid .box {
  margin:0;
  width: calc(100% - 32px);
  flex: 1 0 30%;
  margin: 25px;
  align-self: flex-start;  

}
.site .marketplace_activity_outer {
}
.site .marketplace_activity {
  width: calc(50% - 130px);
    height: ;
  position:fixed;
  right: 50px;
  top:120px;
  bottom: 50px;
  overflow:scroll;
  border-radius: 5px;
  background-size: 100%;
  vertical-align: middle;
  color:#65676b;
  box-shadow:0px 1px 2px rgba(0, 0, 0, 0.2);
  background: #ffffffb5;
  display: ;
}
.site .marketplace_activity_outer .gradient {
  background-image: linear-gradient(180deg, #fff, 70%, rgba(255, 255, 255, 0));
  width: 100%;
  height: 100px;
  top: 0;
  left:0;
  position:absolute;
  display: none;
}
.site .marketplace_activity ul a {
  color: #65676b;
  text-decoration: none;
}
.site .marketplace_activity ul {
  padding:0;
  list-style:none;
  margin:0;
}
.site .marketplace_activity ul li {
  border-bottom: 1px #ccc solid;
  padding: 16px;
  font-size: 13px;

}
.site .marketplace_activity ul li:hover {
  background: #e4e4e4;
}
.site .marketplace_activity ul li:hover .icon svg {
  fill:#b591f0;
}
.site .marketplace_activity ul li .icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}
.site .marketplace_activity ul li .icon svg {
  width: 100%;
  height: 100%;
  fill: #bbbbbb;
}
.site .marketplace_activity ul li bold {
  font-weight: 500;
}
.site .sidebar_feed {
  border-left: 1px #ccc solid;
  float: right;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}
.site .market {
  padding-top:70px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 70px;
}
.site .market .box {
  margin-bottom: 0px;
}
.consoleLog {
  width: 400px;
  position: fixed;
  right: 0;
  top:0;
  background: rgb(17, 17, 17);
  padding: 10px;
  font-family:monospace;
  display: none;
}
.consoleLog .inner {
  color: purple;
}
.react-transform-wrapper {
  overflow:visible !important;
}
.react-transform-component {
}
.connectingLines {
  position: absolute;
  width: 100%;
  height: 100%;
  zoom: 0.25;
}
.connectingLines path {
  position: absolute;
  top:0;
  left:0;
  stroke-width: 1;
  stroke: #eee;
  fill: none;
  stroke-dasharray:0;
}

@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}
.box {
  padding: 16px;
  border-radius: 5px;
  background-size: 100%;
  vertical-align: middle;
  color:#65676b;
  box-shadow:0px 1px 2px rgba(0, 0, 0, 0.2);
  background: #ffffffb5;
  position:;
  width: 100%;
  width: calc(100% - 32px);
  margin-bottom: 50px;
  transition: 0.2s opacity ease, 0.2s background-color ease, 0.2s -webkit-filter ease, 0.2s scale ease, 0.1s blur ease;
  -webkit-transition: 0.2s opacity ease, 0.2s background-color ease, 0.2s -webkit-filter ease, 0.2s scale ease, 0.1s blur ease;
overflow:hidden;
}
.box.react_enabled {
  padding-bottom: 10px;

}
.box.dead {
  opacity: 0.7;
  filter:blur(2px) grayscale(100%) !important;
}
.box.dead:hover {
  filter:blur(0px) grayscale(100%) !important;
  opacity: 1;
}
.dead_thres {
  width: 100%;
    border-bottom: 1px #808080 dashed;
    margin-bottom: 40px;
}
.box.animating {
}
.box .message-text, .box .message-text-inner {
  display: block;
}
.box .message-text {
  max-height: 100px;
  overflow: hidden;
  position:relative;
  transition: max-height 1s ease-out;
  -webkit-transition: max-height 1s ease-out;
}
.box .toggleHeight {
  transition: 0.2s opacity;
  -webkit-transition: 0.2s opacity;
  transition-delay: 1s;
  -webkit-transition-delay: 1s;
}
.box.toggleOpen .toggleHeight {
  opacity:0; 
  transition: 0.2s opacity;
  -webkit-transition: 0.2s opacity;
  cursor: default;

}
.box.toggleOpen .message-text {
  max-height: 999px;
  transition: max-height 1s ease-out;
  -webkit-transition: max-height 1s ease-out;
}
.box .toggleHeight {
  width: 100%;
  height: 80px;
  text-align:center;
  position: absolute;
  left:0;
  color: #fff;
  margin-top: -80px;
  border-bottom: 1px #ccc solid;
  background-image: linear-gradient(0deg, #fff, 70%, rgba(255, 255, 255, 0));
  cursor: pointer;
}
.box .toggleHeight expandbutton {
  font-weight: 500;
  color: var(--button-bg);
  position:absolute;
  bottom: 20px;
  left: 50%;
  transform:translateX(-50%);
}
.box_image {
  width: calc(100%+32px);
  margin-left:-16px;
  margin-right:-16px;
  margin-top:16px;
  display:none;
}
.box_image img {
  width: 100%;
  height: auto;
}
.box:hover {background:#fff;z-index: 9 !important;opacity:1 !important;}
.react-draggable:hover {z-index: 9999 !important;opacity:1 !important;}
.react-draggable {position: absolute;    zoom: 0.5;width: 500px;

}

.box .metabox {
  clear:both;
  overflow:hidden;
  display:flex;
  margin-bottom: 10px;
  
}
.box .metabox .text {padding-left: 10px;}
.box .metabox .timeago {    font-size: 13px;}
.box .metabox .pic {
  width: 40px;
  height:40px;
  border-radius: 100%;
  background:#eee;
  
}
.box .metabox .name {
  font-size: .9375rem;
  font-weight:700;
  margin-top:4px;
  color:#050505;
  letter-spacing:0.1px;
}
.box .meta {
  border-top: #ced0d4 solid 1px;
  padding-top:15px;
  margin-top: 15px;

}
.box .meta .icon {
  display: inline-block;
  margin-right: 10px;
  color: rgb(131, 131, 131);
  font-size: 14px;

}
.box .meta .icon.sub svg {
  fill: rgb(255, 115, 0);
}
.box .meta .icon.pol svg {
  fill: rgb(40, 214, 40);
}
.box .meta .icon svg {
  fill: var(--button-fill);
  vertical-align:middle;
  width: 20px;
  padding-right: 7.5px;
}
.box .reactions_count {
  margin-top:15px;
}
.box .reactions_count .icon {
  width: 20px;
  height: 20px;
  display:inline-block;
  vertical-align: middle;

}
.box .reactions_count .icon > img {
  width:100%;
  height:100%;
  border-radius: 100%;
}

.box .reactions_count .number {
  display:inline-block;
  margin-left: 10px;
  vertical-align: middle;

}
.box .action_bar {
  width: 100%;
  clear:both;
  display: flex;
  border-top: 1px #eee solid;
  padding-top: 10px;
  margin-top: 10px;
}
.box .action_bar .button {
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  width: 50%;
    border-radius: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    transition:background-color 0.2s ease-in-out;
    -webkit-transition:background-color 0.2s ease-in-out;
}
.box .action_bar .button:hover {
  background: #eee;
  transition:background-color 0.2s ease-in-out;
  -webkit-transition:background-color 0.2s ease-in-out;

}
.box .action_bar .button img {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.box .action_bar .button.dislike img {
  transform:rotate(180deg) ;
}
.box .action_bar .button label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  margin-left: 15px;
}
/*.box.hover .action_hover {
  opacity:1;
  margin-top:-65px;
  visibility: visible;  
  pointer-events:  auto;
  transition: opacity 0.2s ease-in-out, margin 0.3s ease-in-out, visibility 0s ease 0.3s;
}*/
.box .action_hover {
  position: absolute;
  background: #fff;
  border-radius: 30px;
  padding: 5px;
  line-height: 0px;
  box-shadow:0px 0px 3px rgba(0, 0, 0, 0.2);
  margin-top:-20px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, margin 0.3s ease-in-out, visibility 0s ease 0.3s;
  -webkit-transition: opacity 0.2s ease-in-out, margin 0.3s ease-in-out, visibility 0s ease 0.3s;
  margin-left:-5px;
  visibility:hidden;
  pointer-events: none;
}
.box.hover .action_hover {
  transition-delay: 0.25s;
  -webkit-transition-delay: 0.25s;
}
.box .action_hover .item {
  width: 40px;
  height: 40px;
  display:inline-block;
  margin-right:5px;
  position:relative;
  text-align: center;
}
.box .action_hover .item:hover .label {opacity:1;}
.box .action_hover .item .label {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 25px;
  top: -35px;
  z-index: 99999;
  position: absolute;
  /* width: 100%; */
  /* height: 20px; */
  color: #fff;
  line-height: 100%;
  padding: 5px;
  font-size:11px;
  left:50%;
  transform:translateX(-50%);
  font-weight:500;
  opacity:0;
  transition:opacity 0.2s;
  -webkit-transition:opacity 0.2s;
}
.box .action_hover .item:last-of-type{margin:0;}
.box .action_hover .item img {
  width: 100%;
  height: auto;
  position:absolute;
  transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  transform-origin: bottom center;
  left:0;
}
.box .action_hover .item img:hover {
  transform: scale(1.2);
  z-index: 999;
}
.box.marketver {
  -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;
  position:relative;
}
.box.marketver.sold {
  opacity: 0.5;
  filter:blur(2px);
}
.box.marketver.sold:hover {
  filter:blur(0px);
}
.box.marketver .owner {
  font-size: 13px;
  margin-bottom: 16px;
  background: #fff;
  padding: 16px;
  margin-left: -16px;
  margin-top: -16px;
  width: 100%;
  box-shadow:0px 1px 2px rgba(0, 0, 0, 0.2);

}
.box.marketver .owner bold {
font-weight: 500;
}
.box.marketver .reactions_count {
  display: inline-block;
  float: left;
}
.box.marketver .buy_button {
  display: inline-block;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  background: var(--button-bg);
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
  transition: 0.2ms ease background-color, 0.2ms ease color, 0.2ms ease transform;
  -webkit-transition: background-color 0.2s ease, color 0.2s ease, -webkit-transform 0.2s ease; 
  position:absolute;
  z-index: 999;
  top:50%;
  right:0;
  transform:translate(0, -50%);
  transform-origin: 50% 300%;
  cursor: pointer;
}
.box.marketver .buy_button:hover {
  background: #eee;
  color: var(--button-bg);
  transform:scale(1.2);
  transition: 0.2ms ease background-color, 0.2ms ease color, 0.2ms ease transform;
  -webkit-transition: background-color 0.2s ease, color 0.2s ease, -webkit-transform 0.2s ease; 
}
.box.marketver .metabar {
  clear: both;
  border-bottom: 1px #ccc solid;
  padding-bottom: 20px;
  position: relative;
}
.box.marketver .metabar .inner {
  overflow: hidden;

}
.box.marketver .data_log {
  clear: both;
  width: 100%;
  list-style: none;
  padding:0;
  margin-top: 20px;

}
.box.marketver .data_log li {
  box-shadow:0px 1px 2px rgba(0, 0, 0, 0.2);
  background: #eee;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 20px;
}
.box.marketver .data_log li:last-of-type{margin:0;}
.tools {
  position:fixed;
  bottom: 10px;
  right: 10px;
  z-index:9999999;
}
.bar {
  width: 70px;
  height: 100%;
  box-shadow:0px 0px 3px rgba(0, 0, 0, 0.2);
  position: fixed;
  top:0;
  left: 0;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index:99999999;
  backdrop-filter: blur(2px);
  vertical-align: middle;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  z-index: 9999999;
  background: #eee;

}
.bar:hover {background:white;}
.bar .icon, .feedBar .icon  {
  width: 40px;
  height: 35px;
  display: inline-block;
  padding-left:15px;
  padding-right:15px;
  padding-top: 15px;
  padding-bottom: 15px;
  position:relative;
}
.feedBar .icon.opensea {
  position: absolute;
  right:50px;
  top:50%;
  text-align: center;
  transform:translate(0,-50%);
}
.bar .icon .mask, .feedBar .icon .mask {
  position:absolute;
  width: 100%;
  height: 100%;
  overflow:hidden;
  left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.bar .icon .borderbox {
  width: 10px;
  height: 100%;
  background: var(--button-bg);
  position: absolute;
  left: -7px;
  /* right: -7px; */
  opacity: 0;
  border-radius: 2px;
  top: 0;
}
.bar .icon .bgbox, .feedBar .icon .bgbox {
  width: 90%;
  height: 50px;
  position: absolute;
  background: #eee;
  top: 0px;
  left: 0;
  z-index: -1;
  border-radius: 7px;
  opacity: 0;
  transition: 0.2s opacity;
  -webkit-transition: 0.2s opacity;
  margin-top: 6.5px;
  margin-left: 5%;

}
.bar .icon:hover .bgbox, .feedBar .icon:hover .bgbox {
  opacity:1;
}
.bar .icon svg, .feedBar .icon svg {
  width: 35px;
  height: 50px;
  padding-top:7.5px;
  padding-bottom:7.5px;
  fill: var(--button-fill);
  vertical-align:middle;
}
.feedBar .icon svg {width:40px;}
.bar_active .icon .borderbox {
  opacity:1 !important;
}
.bar_active .icon .bgbox {
  opacity:0 !important;
}
.bar_active .icon svg {
  fill: var(--button-bg);
}
.bar .icon .titlebox, .feedBar .icon .titlebox {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color:#eee;
  border-radius:6px;
  left:120%;
  top: 50%;
  padding:11px;
  transform:translateY(-50%);
  box-shadow:0px 0px 3px rgba(0, 0, 0, 0.5);
  font-size:14px;
  line-height:100%;
  opacity:0;
  transition: 0.2s opacity;
  transition-delay:0.2s;
  -webkit-transition: 0.2s opacity;
  -webkit-transition-delay:0.2s;
  pointer-events: none;

}
.feedBar .icon .titlebox {
  left:-5.5px;
  top: 130%;
}
.bar .icon:hover .titlebox, .feedBar .icon:hover .titlebox {
  opacity:1;
}
@media screen and (min-width: 2000px) {
  .site .marketplace_activity {
    width: calc(40% - 130px);
  }
  .site .grid {
    width: 60%;
  }
  .site .grid {
    
  }
  .site .grid .box {

  }
}
@media screen and (max-width: 812px) { 
  .box.marketver .data_log li {
    font-size: 10px;
    white-space: pre-wrap;

  }
  .bar {
    width:100%;
    height:70px;
    bottom:0 !important;
    top: auto;
    padding: 0;
  }
  .bar .icon {
    width: 33%;
    padding: 0;
    height:70px;
  }
  .bar .icon .borderbox {
    width: 100%;
    height: 10px;
    top: auto;
    left: 0;
    bottom: -7px;
  }
  .bar .icon .titlebox {
    left: 50%;
    top: -70%;
    transform:translateX(-50%);
  }
  
  .feedBar {
    left:0;
    width: 100%;
    overflow-x:scroll;
    white-space:nowrap;
  }
  .feedBar .section:first-of-type {
    padding-left: 30px !important;
  }
  .site {
    width: calc(80% - 32px) !important;
    margin-left: 10%;
    padding-top: 10%;
    margin-top: 70px;
    margin-bottom: 120px;
    position:static;
  }
  .site .grid, .site .feedInner {
    width: 100% !important;
  }
  .stats_container {
    width: 100vw;
    height: 30vh;
    position: absolute;
    margin: 0 auto;
    /* margin-left: -55px; */
    left: 0;
    top: 80px;
  }
  .timeline_postsection {
    margin-top: 30vh;
  }
  .box {
    width: 100%;
  }
  .main {
    margin: 0px !important;
  }
  .site .marketplace_activity {display: none;}
  .global_loader {
    width: 80%;
    left: 10%;
    top: calc(50vh - 50px);
  }
  .site .marketplace_grid {display:block;}
  .site .marketplace_grid .box {
    width: 100%;
    margin-bottom: 50px;
  }
  .toggleFilter {
    margin-bottom:60px;
  }
  .timeline_postsection {
    width: 100% !important;
  }
  .my-masonry-grid {
    margin-left:0px;
  }
  .my-masonry-grid_column {
    padding-left:0px;
  }
  .feedBar .info-icon {
    display:none;
  }
  
}